import React, { Component } from "react"
import { Link, navigate } from "gatsby"

// Libs
import anime from "animejs/lib/anime.es.js"

// Stores
import QuestionStore from "~stores/QuestionStore"

// Components
import { Row, Cell } from "~layout/Layout"
import QuestionWrapper from "~components/Questions/QuestionsCommons/QuestionWrapper"
import QuestionMetas from "~components/Questions/QuestionsCommons/QuestionMetas"
import QuestionBtnNext from "~components/Questions/QuestionsCommons/QuestionBtnNext"

// Styles
import Styles from "./QuestionInterstice.module.styl"

class QuestionInterstice extends Component {
    constructor(props) {
        super(props)

        // Refs
        this.circleSVG = React.createRef()
        this.questionLeft = React.createRef()
        this.questionRight = React.createRef()
    }

    state = {
        questionData: this.props.pageContext,
        isEnterAnimDone: false,
        resultPercentage: 0,
        tweenedResultPercentage: 0,
        dashArray: 0,
        dashOffset: 0,
    }

    componentDidMount() {
        this.checkIfEverAnswered()

        const circleRadius = this.circleSVG.current.getBoundingClientRect()
                .width,
            computedStrokeDashArray = (2 * Math.PI * circleRadius) / 2

        this.setState(
            {
                dashArray: computedStrokeDashArray,
                dashOffset: computedStrokeDashArray,
            },
            () => {
                const callback = this.startAnimation.bind(this)

                if (this.state.questionData.queryData) {
                    QuestionStore.getMetrics(
                        this.state.questionData.queryData,
                        (response) => {
                            this.setState(
                                {
                                    resultPercentage:
                                        (response.candidateAnswersNumber /
                                            response.totalAnswersNumber) *
                                        100,
                                },
                                () => {
                                    callback()
                                }
                            )
                        },
                        () => {}
                    )
                } else if (this.state.questionData.staticData) {
                    this.setState(
                        {
                            resultPercentage: this.state.questionData
                                .staticData,
                        },
                        () => {
                            callback()
                        }
                    )
                }
            }
        )
    }

    startAnimation() {
        let startValues = {
            dashOffset: this.state.dashOffset,
            tweenedNumber: 0,
        }

        let timeline = anime.timeline({
            easing: "easeOutExpo",
            autoplay: false,
            delay: 200,
        })

        timeline.add({
            targets: this.questionLeft.current,
            translateX: ["1rem", 0],
            opacity: [0, 1],
            duration: 400,
        })

        timeline.add(
            {
                targets: this.questionRight.current,
                translateX: ["1rem", 0],
                opacity: [0, 1],
                duration: 400,
                complete: (anim) => {
                    if (this.questionRight && this.questionRight.current) {
                        this.questionRight.current.style.transform = ``
                    }
                },
            },
            100
        )

        timeline.add(
            {
                targets: startValues,
                dashOffset:
                    this.state.dashOffset *
                    (1 - this.state.resultPercentage / 100),
                tweenedNumber: `${this.state.resultPercentage}`,
                round: 1,
                duration: 1000,
                easing: "easeInOutQuad",
                update: (anim) => {
                    this.setState({
                        dashOffset: startValues.dashOffset,
                        tweenedResultPercentage: startValues.tweenedNumber,
                    })
                },
                complete: (anim) => {
                    this.setState({
                        isEnterAnimDone: true,
                    })
                },
            },
            "-=200"
        )

        timeline.play()
    }

    setExitAnimation(callback) {
        const timeline = anime.timeline({
            easing: "easeInOutExpo",
            delay: 200,
        })

        timeline.add({
            targets: this.questionLeft.current,
            translateX: [0, "-1rem"],
            opacity: [1, 0],
            duration: 400,
        })

        timeline.add(
            {
                targets: this.questionRight.current,
                translateX: [0, "-1rem"],
                opacity: [1, 0],
                duration: 400,
                complete: (anim) => {
                    callback()
                },
            },
            100
        )
    }

    checkIfEverAnswered() {
        if (!QuestionStore.checkIfEverAnswered()) {
            navigate(`/questions/1`)
            return
        }
    }

    goToNext() {
        this.setExitAnimation(() => {
            navigate(`/questions/${this.props.pageContext.next}`)
        })
    }

    render() {
        return (
            <QuestionWrapper
                currentQuestionID={this.state.questionData.id}
                currentTheme={this.state.questionData.theme}
            >
                <div className={Styles.QuestionInterstice}>
                    <div className={Styles.QuestionInterstice__inner}>
                        <Row full={true}>
                            <Cell start="1" end="5">
                                <div
                                    className={Styles.QuestionInterstice__left}
                                    ref={this.questionLeft}
                                >
                                    <div
                                        className={
                                            Styles.QuestionInterstice__circle
                                        }
                                    >
                                        <svg
                                            viewBox="0 0 48 48"
                                            width="100%"
                                            height="100%"
                                        >
                                            <ellipse
                                                ref={this.circleSVG}
                                                className={
                                                    Styles.QuestionInterstice__circle__item
                                                }
                                                vectorEffect="non-scaling-stroke"
                                                fill="none"
                                                strokeWidth="4"
                                                stroke="#000"
                                                cx="24"
                                                cy="24"
                                                rx="23"
                                                ry="23"
                                                opacity="1"
                                                strokeDasharray={
                                                    this.state.dashArray
                                                }
                                                strokeDashoffset={
                                                    this.state.dashOffset
                                                }
                                            />
                                            <ellipse
                                                className={
                                                    Styles.QuestionInterstice__circle__item
                                                }
                                                vectorEffect="non-scaling-stroke"
                                                fill="none"
                                                strokeWidth="1"
                                                stroke="#000"
                                                cx="24"
                                                cy="24"
                                                rx="23"
                                                ry="23"
                                                opacity="1"
                                            />
                                        </svg>
                                        <div
                                            className={`${Styles.QuestionInterstice__circle__inner}`}
                                        >
                                            <span
                                                className={`${Styles.QuestionInterstice__circle__progress} tag`}
                                            >
                                                Votre progression :
                                            </span>
                                            <span
                                                className={`${Styles.QuestionInterstice__circle__number} heading-huge`}
                                            >
                                                {
                                                    this.state
                                                        .tweenedResultPercentage
                                                }
                                                %
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Cell>
                            <Cell start="6" end="10">
                                <div
                                    className={Styles.QuestionInterstice__right}
                                    ref={this.questionRight}
                                >
                                    {this.state.questionData.title && (
                                        <span
                                            className={`tag ${Styles.QuestionInterstice__right__top}`}
                                        >
                                            {this.state.questionData.title}
                                        </span>
                                    )}
                                    <h1
                                        className={`teasing-2 ${Styles.QuestionInterstice__right__body}`}
                                    >
                                        {
                                            this.state.questionData.sentences
                                                .trailing
                                        }
                                    </h1>
                                </div>
                            </Cell>
                        </Row>
                    </div>
                </div>

                {this.state.isEnterAnimDone && (
                    <QuestionBtnNext
                        onClick={this.goToNext.bind(this)}
                        label={`Suivant`}
                    />
                )}
            </QuestionWrapper>
        )
    }
}

export default QuestionInterstice
